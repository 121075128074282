import React, { Component } from "react";

import ReCAPTCHA from "react-google-recaptcha";

class BetaSection extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", subject: "", msg: "", cap: "", sent: false };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ msg: event.target.value });
    this.setState({ email: event.target.value });
  }
  handleSubjectChange(event) {
  
    this.setState({ subject: event.target.value });
  }
  handleMessageChange(event) {
    this.setState({ msg: event.target.value });
  }

  onCapChange(value) {
    this.setState({ cap: value });
  }

  handleSubmit(event) {
    const templateId = "template_FuA5eHt6";

    this.sendFeedback(templateId, {
      subject:  "Beta Request: "+ this.state.subject,
      message_html: this.state.msg,
      from_name: this.state.name,
      reply_to: this.state.email,
      "g-recaptcha-response": this.state.cap,
    });
  }

  sendFeedback(templateId, variables) {

    if(this.state.name === "" || this.state.subject === "" || this.state.msg === "" || !this.state.email.includes('@')) {
      return;
    }

    window.emailjs
      .send("gmail", templateId, variables)
      .then((res) => {
        this.setState({
          name: "",
          email: "",
          subject: "",
          msg: "",
          cap: "",
          sent: true
        });
        console.log("Email successfully sent!");
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) =>
        console.error(
          "Oh well, you failed. Here's some thoughts on the error that occured:",
          err
        )
      );
  }

  render() {
    return (
      <section id="contact" className="contact-area bg-gray ptb_100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              {/* Section Heading */}
              <div className="section-heading text-center">
                <h2 className="text-capitalize">{"Sign Up to our Beta"}</h2>
                <p className="d-none d-sm-block mt-4">
                  {
                    "Interested in testing our product before release? Sign up below for a chance to be invited to our beta program."
                  }
                </p>
                <p className="d-block d-sm-none mt-4">{""}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {/* Contact Box */}
              <div className="contact-box text-center">
                {/* Contact Form */}
                {!this.state.sent ? <form
                  id="contact-form"
                  method="POST"
                  action="assets/php/mail.php"
                >
                  <div className="row" style={{justifyContent: "center"}}>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          id="1"
                          type="text"
                          className="form-control"
                          name="name"
                          placeholder="Name"
                          required="required"
                          value={this.state.name}
                          onChange={this.handleNameChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Platform (iOS / Android)"
                          required="required"
                          value={this.state.subject}
                          onChange={this.handleSubjectChange}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email (Apple/Google acc.)"
                          required="required"
                          value={this.state.email}
                          onChange={this.handleEmailChange}
                        />
                      </div>
                      <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 20,
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                 
                      <ReCAPTCHA
                        onChange={this.onCapChange}
                        sitekey="6Ld0Hq0ZAAAAAJzCAJ1ldQhI1g0dpDEXRZryqLCh"
                      />
                    </div>
                    </div>
                    
                    <div className="col-12">
                      <button
                        className="btn btn-bordered mt-3 mt-sm-4"
                        type="submit"
                        onClick={this.handleSubmit}
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form> : <div style={{backgroundColor: "white", margin: "20px", padding: "20px", borderRadius: "10px"}}>Thanks for Signing up to our beta. You will receive an email in the coming weeks if you are accepted.</div>}
                
                <p className="form-message" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BetaSection;
