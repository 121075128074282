import React, { Component } from "react";

const initData = {
  heading: "Few steps to Setup",
  headingText:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
  headingTextTwo:
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
};

const imgData = [
  {
    image: "/img/login-screen.png",
  },
  {
    image: "/img/addcar-screen.png",
  },
  {
    image: "/img/find-screen.png",
  },
  {
    image: "/img/activity-screen.png",
  },
];

const workData = [
  {
    linkClass: "pager-item active",
    title: "Login",
    text:
      "Download discarvr from Google Play or the App Store and Login with your Facebook Account to get started.",
  },
  {
    linkClass: "pager-item",
    title: "Add your Vehicles",
    text:
      "Create your discarvr profile and add Vehicles to your garage to share your passion for cars with others.",
  },
  {
    linkClass: "pager-item",
    title: "Find Car Events",
    text:
      "Check out Events that discarvr recommends, or search the Map for Events happening around the UK.",
  },
  {
    linkClass: "pager-item",
    title: "Get Involved",
    text:
      "Add your own car related Events from Facebook, and get the word out other discarvr users.",
  },
];

class workOne extends Component {
  state = {
    initData: {},
    data: [],
    workData: [],
  };
  componentDidMount() {
    this.setState({
      initData: initData,
      data: imgData,
      workData: workData,
    });
  }
  render() {
    return (
      <section className="section work-area ptb_100">
        {/* Work Slider Wrapper */}
        <div className="work-wrapper d-none d-md-block">
          <div className="work-slider-wrapper" data-aos="zoom-in">
            {/* Work Slider */}
            <ul className="work-slider owl-carousel">
              {this.state.data.map((item, idx) => {
                return (
                  <li key={`wd_${idx}`} className="slide-item">
                    <img src={item.image} alt="" />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="container">
          {/* Work Content */}
          <div
            className="row justify-content-end justify-content-lg-between work-content"
            id="work-slider-pager"
          >
            {this.state.workData.map((item, idx) => {
              return (
                <div key={`wdo_${idx}`} className="col-12 col-sm-6">
                  <div className={item.linkClass}>
                    {/* Single Work */}
                    <div className="single-work d-inline-block text-center p-4">
                      <h3 className="mb-2">{item.title}</h3>
                      <p>{item.text}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default workOne;
