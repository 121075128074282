import React from 'react';

import MyRoutes from './routers/routes'

function App() {
  return (
    <div>
      <MyRoutes />
    </div>
  );
}

export default App;
