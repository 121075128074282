import React, { Component } from "react";
import axios from "axios";

import json from "../../data/promo.json";

const PROXY = "https://guarded-woodland-08071.herokuapp.com/";
const OVERVIEW_LINK_S =
  "https://europe-west1-carmeet-6e3ed.cloudfunctions.net/overview-get?key=1h128e34-0641-4h66-a6f8-2b74a81e13a1";

  const OVERVIEW_LINK = "https://europe-west1-discarvr.cloudfunctions.net/overview-get?key=1h128e34-0641-4h66-a6f8-2b74a81e13a1"

class promoOne extends Component {
  state = {
    promoData: json.promoData,
    discarvrData: {},
  };

  componentDidMount() {
    axios
      .get(PROXY + OVERVIEW_LINK, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
      })
      .then((res) => {

        this.setState({
          promoData: json.promoData,
          discarvrData: res.data,
        });
        console.log(this.state.discarvrData.totalEvents);
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <section id="benifits" className="section benifits-area ptb_100">
        <div className="container">
          <div className="row">
            <div
              key={`po_${this.state.promoData[0].id}`}
              className="col-12 col-sm-6 col-md-4 col-lg-3"
              data-aos="fade-up"
            >
              <div className="benifits-item text-center p-3">
                <div className="counter-text">
                  <span className=" d-inline-block mt-3 mb-2">
                    {this.state.discarvrData.totalEvents === undefined
                      ? 0
                      : this.state.discarvrData.totalEvents}
                  </span>
                  <h5>{this.state.promoData[0].text}</h5>
                </div>

                <div className="benifits-text">
                  <h3 className="mb-2">{this.state.promoData[0].promoTitle}</h3>
                  <p>{this.state.promoData[0].promoText}</p>
                </div>
              </div>
            </div>
            <div
              key={`po_${this.state.promoData[1].id}`}
              className="col-12 col-sm-6 col-md-4 col-lg-3"
              data-aos="fade-up"
            >
              <div className="benifits-item text-center p-3">
                <div className="counter-text">
                  <span className=" d-inline-block mt-3 mb-2">
                    {this.state.discarvrData.totalVehicles === undefined
                      ? 0
                      : this.state.discarvrData.totalVehicles + 12}
                  </span>
                  <h5>{this.state.promoData[1].text}</h5>
                </div>

                <div className="benifits-text">
                  <h3 className="mb-2">{this.state.promoData[1].promoTitle}</h3>
                  <p>{this.state.promoData[1].promoText}</p>
                </div>
              </div>
            </div>
            <div
              key={`po_${this.state.promoData[2].id}`}
              className="col-12 col-sm-6 col-md-4 col-lg-3"
              data-aos="fade-up"
            >
              <div className="benifits-item text-center p-3">
                <div className="counter-text">
                  <span className=" d-inline-block mt-3 mb-2">
                    {this.state.discarvrData.totalUsers === undefined
                      ? 0
                      : this.state.discarvrData.totalUsers}
                  </span>
                  <h5>{this.state.promoData[2].text}</h5>
                </div>

                <div className="benifits-text">
                  <h3 className="mb-2">{this.state.promoData[2].promoTitle}</h3>
                  <p>{this.state.promoData[2].promoText}</p>
                </div>
              </div>
            </div>
            <div
              key={`po_${this.state.promoData[3].id}`}
              className="col-12 col-sm-6 col-md-4 col-lg-3"
              data-aos="fade-up"
            >
              <div className="benifits-item text-center p-3">
                <div className="counter-text">
                  <span className=" d-inline-block mt-3 mb-2">
                    {this.state.discarvrData.totalCheckIns === undefined
                      ? 0
                      : this.state.discarvrData.totalCheckIns+8}
                  </span>
                  <h5>{this.state.promoData[3].text}</h5>
                </div>

                <div className="benifits-text">
                  <h3 className="mb-2">{this.state.promoData[3].promoTitle}</h3>
                  <p>{this.state.promoData[3].promoText}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default promoOne;
