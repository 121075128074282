import React, { Component } from "react";
import data from "../../data/footer.json";

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/appo-json-2/themeOneFooterSection";

class footer extends Component {
  state = {
    data: {},
    iconList: [],
    footerList_1: [],
    footerList_2: [],
    footerList_3: [],
  };
  componentDidMount() {
    this.setState({
      data: data,
      iconList: data.iconList,
      footerList_1: data.footerList_1,
      footerList_2: data.footerList_2,
      footerList_3: data.footerList_3,
    });
  }
  render() {
    return (
      <footer className="section footer-area footer-bg">
        {/* Footer Top */}
        <div className="footer-top ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Logo */}
                  <div className="navbar-brand">
                    <img className="logo" src={this.state.data.image} alt="" />
                  </div>
                  <a className="navbar-brand" href="https://koroutine.tech/">
                    <svg
                      className="logo"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 400 300"
                      width="50px"
                    >
                      <g transform="translate(40,150) rotate(-45)">
                        <g>
                          <rect
                            x="0"
                            y="0"
                            width="50"
                            height="150"
                            fill="#90a4ae"
                          ></rect>
                          <rect
                            x="0"
                            y="0"
                            width="150"
                            height="50"
                            fill="#78909c"
                          ></rect>
                        </g>
                        <g transform="translate(75,75)">
                          <rect
                            x="0"
                            y="100"
                            width="150"
                            height="50"
                            fill="#78909c"
                          ></rect>
                          <rect
                            x="100"
                            y="0"
                            width="50"
                            height="150"
                            fill="#607d8b"
                          ></rect>
                          <rect
                            x="0"
                            y="0"
                            width="150"
                            height="50"
                            fill="#546e7a"
                          ></rect>
                        </g>
                      </g>
                    </svg>
                  </a>
                  <p className="text-white-50 mt-2 mb-1">
                    {this.state.data.text}
                  </p>
                  <a
                    className="navbar-brand mb-1"
                    href="https://koroutine.tech/"
                  >
                    koroutine.tech
                  </a>
                  <p className="text-white-50 mt-1 mb-1">
                    {this.state.data.text2}
                  </p>
                  {/* Social Icons */}
                  <div className="social-icons d-flex">
                    {this.state.iconList.map((item, idx) => {
                      return (
                        <a
                          key={`sil_${idx}`}
                          className={item.class}
                          href={item.link}
                        >
                          <i className={item.iconClass} />
                          <i className={item.iconClass} />
                        </a>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h3 className="footer-title text-white mb-2">
                    {this.state.data.linkText_1}
                  </h3>
                  <ul>
                    {this.state.footerList_1.map((item, idx) => {
                      return (
                        <li key={`flo_${idx}`} className="py-2">
                          <a className="text-white-50" href={item.link}>
                            {item.text}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h3 className="footer-title text-white mb-2">
                    {this.state.data.linkText_2}
                  </h3>
                  <ul>
                    {this.state.footerList_2.map((item, idx) => {
                      return (
                        <li key={`flo_${idx}`} className="py-2">
                          <a className="text-white-50" href={item.link}>
                            {item.text}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h3 className="footer-title text-white mb-2">
                    {this.state.data.linkText_3}
                  </h3>
                  <ul>
                    {this.state.footerList_3.map((item, idx) => {
                      return (
                        <li key={`flo_${idx}`} className="py-2">
                          <a className="text-white-50" href={item.link} target="_blank" rel="noopener noreferrer" >
                            {item.text}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Copyright Area */}
                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                  {/* Copyright Left */}
                  <div className="copyright-left text-white-50">
                    &copy; Copyright 2020 discarvr. All rights reserved
                  </div>
                  {/* Copyright Right */}
                  {/*<div className="copyright-right text-white-50">secondary copyright</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default footer;
