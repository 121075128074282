import React, { Component } from "react";
import data from "../../data/about.json";

const aboutInfo = {
  headingSpan: "About",
  heading: "Participate in Car Events with discarvr",
  headingText:
    "Finding car events can be difficult. Discarvr harnesses the power of third party providers (with our first being Facebook) to bring you all the events in your given criteria. Events are constantly updated from our third parties, giving you the most accurate event information available.",
  headingTextTwo:
    "We provide as much information as we can to help you find your next car event. Better yet, unlike other event finding services, we don’t stop there! Once you’re at an event, you can participate in various activities by 'Checking in' to the event with your smart phones location which allows you to build your profile and give your vehicle the exposure it truly deserves.",
  headingTextThree:
    "How do we do it? We use machine learning to determine which events from our providers are car related. This ensures that no event in discarvr is ever duplicated, all events are accurate and your event feed is never filled with non-car related events.",
  aboutThumb: "/img/about.png" ,
};

class aboutOne extends Component {
  state = {
    data: {},
    aboutData: data,
  };
  componentDidMount() {
    this.setState({
      data: aboutInfo,
    });
  }
  render() {
    return (
      <section id="about" className="section about-app-area ptb_100">
        <div
          className="container"
          style={{ paddingRight: "0px", paddingBottom: "0px" }}
        >
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              {/* About Text */}
              <div className="about-text">
                {/* Headings */}
                <div className="headings d-flex align-items-center mb-4">
                  <h2>{this.state.data.heading}</h2>
                </div>
                <p className="my-3">{this.state.data.headingText}</p>
                <p className="d-none d-sm-block my-3">
                  {this.state.data.headingTextTwo}
                </p>
                <p className="d-none d-sm-block my-3">
                  {this.state.data.headingTextThree}
                </p>
                {/* Store Buttons */}
                <div className="button-group store-buttons">
                  {this.state.aboutData.aboutData.map((item, idx) => {
                    return (
                      <a key={`ao_${idx}`} href={item.url} target="_blank" rel="noopener noreferrer" className={item.linkClass}>
                        <i className={item.iconClass} />
                        <p className="dsp-tc">
                          {item.iconText}
                          <br /> <span>{item.iconSpan}</span>
                        </p>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              {/* About Thumb */}
              <div className={"about-background"}></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default aboutOne;
