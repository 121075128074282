import React, { Component } from "react";

import ScrollupSection from "../components/scrollupSection/scrollUp";
import Header from "../components/headerSection/header";
import WelcomeSection from "../components/welcomeSection/welcomeOne";
import PromoSection from "../components/promoSection/promoOne";
import AboutSection from "../components/aboutSection/aboutOne";
import WorkSection from "../components/workSection/workOne";
import FeatureSection from "../components/featureSection/featureOne";
import DownloadSection from "../components/downloadSection/downloadOne";
import ContactSection from "../components/contactSection/contact";
import BetaSection from "../components/betaSection/beta";
import FooterSection from "../components/footerSection/footer";

class Theme extends Component {
  render() {
    return (
      <div className="miami">
        <ScrollupSection />
        <div className="all-area">
          <Header imageData={"/img/logo.png"} />
          <WelcomeSection />
          <PromoSection />
          <AboutSection />
          <WorkSection />
          <FeatureSection />
          <DownloadSection />
          <ContactSection />
          {/*<BetaSection/>*/}
          <FooterSection />
        </div>
      </div>
    );
  }
}

export default Theme;
