import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import Theme from "../theme/theme";


class MyRoutes extends React.Component {
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route exact path="/" component={Theme} />
                    </Switch>
                </Router>
            </div>
        );
    }
}
export default MyRoutes;