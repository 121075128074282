import React, { Component } from "react";

import data from "../../data/storeButtons.json";

const initData = {
  heading: "discarvr",
  headingBadge: "v1.0",
  headingSpan: "discover",
  content_1: "car events",
  content_2: "fellow car enthusiasts",
  content_3: "car meets",
  content_4: "organised drives",
  content_5: "driving roads",
  text:
    "Can’t find car events in your local area? discarvr does the work for you! discarvr allows you to find and participate in car related Events, Meets and Drives near you. Share photos and info about your car with like minded enthusiasts, and keep track of all the Events you and your friends have attended with much more to come!",
  btnText: "Buy Now",
  btnTextTwo: "Learn More",
  welcomeThumb: "/img/iphone-main.png",
  iconClass: "icofont-ui-play",
};

class welcomeOne extends Component {
  state = {
    data: {},
    storeData: data,
  };
  componentDidMount() {
    this.setState({
      data: initData,
    });
  }
  render() {
    return (
      <section
        id="home"
        className="section welcome-area d-flex align-items-center"
      >
        <div className="container">
          <div className="row align-items-center">
            {/* Welcome Intro Start */}
            <div className="col-12 col-md-7 col-lg-6">
              <div className="welcome-intro">
                <img
                  src="/img/discarvr_full_grey_250x940.png"
                  alt=""
                  className="discarvr-logo"
                />
                {/*<h1 className=" discarvr-font">{this.state.data.heading} </h1>*/}
                <h3 className="cd-headline clip fw-3 mt-2 mt-sm-3">
                  <span className=" mr-2">{this.state.data.headingSpan}</span>
                  <span className="cd-words-wrapper">
                    <b className=" is-visible">{this.state.data.content_1}</b>
                    <b className="">{this.state.data.content_2}</b>
                    <b className="">{this.state.data.content_3}</b>
                    <b className="">{this.state.data.content_4}</b>
                    <b className="">{this.state.data.content_5}</b>
                  </span>
                </h3>
                <p className="my-3">{this.state.data.text}</p>
                <div className="button-group store-buttons">
                  {this.state.storeData.storeButtons.map((item, idx) => {
                    return (
                      <a key={`ao_${idx}`} href={item.url} target="_blank" rel="noopener noreferrer"  className={item.linkClass}>
                        <i className={item.iconClass} />
                        <p className="dsp-tc">
                          {item.iconText}
                          <br /> <span>{item.iconSpan}</span>
                        </p>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6">
              {/* Welcome Thumb */}
              <div className="welcome-thumb text-center">
                <img src={this.state.data.welcomeThumb} alt="" />
              </div>
              {/* Video Icon */}
              {/*<div className="video-icon d-none d-lg-block">
                                <a className="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                                    <i className={this.state.data.iconClass} />
                                </a>
                                </div>*/}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default welcomeOne;
