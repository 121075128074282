import React, { Component } from 'react';

import data from '../../data/storeButtons.json'

const initData = {
    heading: "Download discarvr",
    headingText: "Discarvr is available on Google Play and the App Store.",
    headingTextTwo: "You can keep up to date with all discarvr news by ",
    facebookLinkText: "following us on Facebook."
}

class downloadOne extends Component {
    state = {
        initData: initData,
        downloadData: data
    }
    componentDidMount() {
        this.setState({
            downloadData: data
        })
    }
    render() {
        return (
            <section id="download" className="section download-area ptb_100">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            {/* Download Text */}
                            <div className="download-text text-center">
                                <img
                                    src="/img/logo.png"
                                    alt=""
                                    className="download-icon"
                                />
                                <h2>{this.state.initData.heading}</h2>
                                <p className="my-3">{this.state.initData.headingText}</p>
                                <p className="d-none d-sm-block my-3">{this.state.initData.headingTextTwo}<a href="https://www.facebook.com/discarvr/">{this.state.initData.facebookLinkText}</a></p>
                                {/* Store Buttons */}
                                <div className="button-group store-buttons">
                                    {this.state.downloadData.storeButtons.map((item, idx) => {
                                        return (
                                            <a key={`do_${idx}`} href={item.url} target="_blank" rel="noopener noreferrer"  className={item.linkClass}>
                                                <i className={item.iconClass} />
                                                <p className="dsp-tc">{item.iconText}
                                                    <br /> <span>{item.iconSpan}</span></p>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default downloadOne;